/* purgecss start ignore */
@tailwind base;
@tailwind utilities;
@tailwind components;
/* purgecss end ignore */

@import './fonts/fonts.scss';

h1,
h2 {
  font-family: 'Alice';
  text-align: center;
}

h3,
h4,
p,
a,
li {
  font-family: 'Lato';
}

h1 {
  @apply text-6xl py-5 uppercase;
}

h2 {
  // font-style: italic;
  @apply text-3xl font-bold;
}

h3 {
  @apply text-2xl font-bold;
}

h4 {
  @apply text-xl font-bold;
}

p,
li {
  @apply text-lg;
}

a {
  @apply text-blue-900;
}

.navbar {
  padding-left: 25vw;
  padding-right: 25vw;
  overflow: hidden;
}

.body {
  padding-left: 20vw;
  padding-right: 20vw;
}

.thinBody {
  padding-left: 30vw;
  padding-right: 30vw;
}

.navbar Link {
  float: left;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  @apply font-extrabold;
}

.nav-link {
  @apply text-black uppercase;
}

.subnav {
  float: left;
  overflow: hidden;
}

.subnav .subnavbtn {
  border: none;
  outline: none;
  background-color: inherit;
  font-family: inherit;
  color: #000000;
  text-transform: uppercase;
  margin: 0;
}

.navbar Link:hover,
.subnav:hover .subnavbtn {
  background-color: inherit;
}

.subnav-content {
  display: none;
  position: relative;
  left: 0;
  background-color: inherit;
  width: 100%;
  z-index: 1;
}

.subnav-content Link {
  float: left;
  text-decoration: none;
}

.subnav-content Link:hover {
  background-color: #eee;
  color: silver;
}

.subnav:hover .subnav-content {
  display: flex;
  flex-direction: column;
}

td {
  border: 1px solid;
}
